import { Routes } from '@angular/router';

export const PAGES_MENU: Routes = [
  {
    path: 'pages',
    children: [
      {
        path: 'dashboard',
        data: {
          menu: { title: 'Dashboard', icon: 'ion-android-home', selected: false, expanded: false, order: 0 },
        },
      },
      {
        path: 'manage',
        data: {
          menu: { title: 'Manage', icon: 'ion-gear-b', selected: false, expanded: false, order: 100 },
        },
        children: [
          {
            path: 'bookings',
            data: {
              menu: { title: 'Bookings' },
            },
          },
          {
            path: 'countrylist',
            data: {
              menu: { title: 'Countries' },
            },
          },
          {
            path: 'customer-support',
            data: {
              menu: { title: 'Customer Support', selected: false, expanded: false, order: 100 },
            },
            children: [
              {
                path: 'sms-sender',
                data: {
                  menu: { title: 'Sms Sender' },
                },
              },
            ],
          },
          {
            path: 'discountcodes',
            data: {
              menu: { title: 'Discount Codes' },
            },
          },
          {
            path: 'holidaylist',
            data: {
              menu: { title: 'Holidays' },
            },
          },
          {
            path: 'issuelist',
            data: {
              menu: { title: 'Issues' },
            },
          },
          {
            path: 'landingpages',
            data: {
              menu: { title: 'Landing Pages' },
            },
          },
          {
            path: 'locationlist',
            data: {
              menu: { title: 'Locations' },
            },
          },
          {
            path: 'marketing',
            data: {
              menu: { title: 'Marketing', selected: false, expanded: false, order: 100 },
            },
            children: [
              { path: 'ads-keyword-templates', data: { menu: { title: 'Ad Keywords' } } },
              { path: 'ads-template-pois', data: { menu: { title: 'Ad Template POIs' } } },
              { path: 'ads-template-locations', data: { menu: { title: 'Ad Template Locations' } } },
            ],
          },
          {
            path: 'pointer',
            data: {
              menu: { title: 'Pointer', selected: false, expanded: false, order: 100 },
            },
            children: [
              { path: 'scan', data: { menu: { title: 'POI Scan' } } },
              { path: 'list', data: { menu: { title: 'POI Result' } } },
              { path: 'seed-scan', data: { menu: { title: 'Seed Scan' } } },
              { path: 'lead-scan', data: { menu: { title: 'Lead Scan' } } },
              { path: 'import-candidates', data: { menu: { title: 'Import Candidates' } } },
            ],
          },
          {
            path: 'products',
            data: {
              menu: { title: 'Products', selected: false, expanded: false, order: 100 },
            },
            children: [
              { path: 'product-list', data: { menu: { title: 'Product List' } } },
              { path: 'product-group-list', data: { menu: { title: 'Product Group List' } } },
              {
                path: 'price-contexts',
                data: {
                  menu: {
                    title: 'Price Contexts',
                  },
                },
              },
              {
                path: 'price-list',
                data: {
                  menu: {
                    title: 'Price List',
                  },
                },
              },
            ],
          },
          {
            path: 'pois',
            data: {
              menu: { title: 'Points of Interest', selected: false, expanded: false, order: 100 },
            },
            children: [
              { path: 'pointsofinterest', data: { menu: { title: 'POI List' } } },
              // { path: 'pointsofinterest-staging', data: { menu: { title: 'Candidates' } } },
              { path: 'poiscoreconfig', data: { menu: { title: 'Coverage Score Config' } } },
            ],
          },
          // Disabling legacy pricing view as it will be replaced by views to manage new order/product-based pricing
          // {
          //   path: 'pricinglist',
          //   data: {
          //     menu: { title: 'Pricing' },
          //   },
          // },
          {
            path: 'split-testing',
            data: {
              menu: { title: 'Split Testing', selected: false, expanded: false, order: 100 },
            },
            children: [
              { path: 'experiments', data: { menu: { title: 'Experiments' } } },
              { path: 'variants', data: { menu: { title: 'Variants' } } },
            ],
          },
          {
            path: 'users',
            data: {
              menu: { title: 'Users' },
            },
          },
          {
            path: 'reviews',
            data: {
              menu: { title: 'Reviews' },
            },
          },
          {
            path: 'securitysealtransactionlist',
            data: {
              menu: { title: 'Security Seals' },
            },
          },
          {
            path: 'shoplist',
            data: {
              menu: { title: 'Shops' },
            },
          },
          {
            path: 'storagelocationagreements',
            data: {
              menu: { title: 'TOS Agreements' },
            },
          },
          {
            path: 'themeslist',
            data: {
              menu: { title: 'Themes' },
            },
          },
          {
            path: 'transactionslist',
            data: {
              menu: { title: 'Transactions' },
            },
          },
        ],
      },
      // {
      //   path: 'reporting',
      //   data: {
      //     menu: { title: 'Reporting', icon: 'ion-cash', selected: false, expanded: false, order: 100 },
      //   },
      //   children: [
      //     { path: 'domo', data: { menu: { title: 'Domo' } } },
      //     { path: 'payments', data: { menu: { title: 'Payments' } } },
      //     { path: 'paymentsbymonth', data: { menu: { title: 'Payments By Month' } } },
      //     { path: 'vatreport', data: { menu: { title: 'Vat Report' } } },
      //     { path: 'noshow', data: { menu: { title: 'No Shows' } } },
      //     { path: 'returncustomerlist', data: { menu: { title: 'Return Customers' } } },
      //   ],
      // },
    ],
  },
  {
    path: 'logout',
    data: {
      menu: {
        title: 'Log out',
        icon: 'ion-log-out',
        selected: false,
        expanded: false,
        order: 400,
      },
    },
  },
];
