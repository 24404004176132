import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';
import Multitenancy from 'supertokens-web-js/recipe/multitenancy';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

SuperTokens.init({
  // enableDebugLogs: true,
  appInfo: {
    apiDomain: environment.IDENTITY_API,
    appName: 'LuggageHero',
  },
  recipeList: [
    Session.init({
      tokenTransferMethod: 'cookie',
      // sessionTokenFrontendDomain: 'luggagehero.com',
      // sessionTokenBackendDomain: 'luggagehero.com',
      override: {
        functions: (originalImplementation) => ({
          ...originalImplementation,
          shouldDoInterceptionBasedOnUrl: (toCheckUrl, apiDomain, sessionTokenBackendDomain) => {
            if (environment.ENV === 'DEV' && toCheckUrl.includes('localhost')) {
              return true;
            }

            // FIX: if we are logged in on a dev/staging env we should not intercept requests to the main site
            if (toCheckUrl.includes('.luggagehero.com')) {
              return true;
            }

            // Call the original implementation for all other cases
            return originalImplementation.shouldDoInterceptionBasedOnUrl(
              toCheckUrl,
              apiDomain,
              sessionTokenBackendDomain
            );
          },
        }),
      },
    }),
    EmailPassword.init(),
    Multitenancy.init({
      override: {
        functions: (originalImplementation) => {
          return {
            ...originalImplementation,
            getTenantId: () => {
              return 'admin-luggagehero';
            },
          };
        },
      },
    }),
  ],
});
